'use client';
import {
	Box,
	Card,
	CardActions,
	CardContent,
	Grid,
	IconButton,
	Rating,
	Typography,
} from '@mui/material';
import { IProductCatalog } from '@negotium/models';
import React, { useEffect, useState } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useClientTranslation, useSessionClient } from '@negotium/common';
import {
	addUserRateToProduct,
	addUserToWhishList,
} from 'apps/customer-portal/app/lib/product/actions';
import { useNotification } from '../ErrorContext';
import Link from 'next/link';
import { formatCurrency } from 'apps/customer-portal/utils/numWithComma';

import { ImageWithFallback } from '../ImageWithFallback';
import { ButtonAddProductCardShop } from '../ButtonAddProductCardShop';
import { useContextGeneral } from '../ContextGeneral';
import { StarOutlined, StarSolid } from '../Svgs';
import ThemeBasic from 'apps/customer-portal/utils/theme';

type CardProductHorizontalProps = {
	product: IProductCatalog;
	lng: 'es' | 'en';
	setWishListUserLogged: (data: boolean) => void;
	onAddProduct?: (storageUniqueId: string, amount: number) => void;
	wholesaler?: boolean;
};

export const CardProductHorizontal = ({
	product,
	lng,
	onAddProduct,
	setWishListUserLogged,
	wholesaler = false,
}: CardProductHorizontalProps) => {
	const { t } = useClientTranslation(lng, ['catalog']);
	const { setErrorNotification, setSuccessNotification } = useNotification();
	const [rating, setRating] = useState(4);

	const user = useSessionClient();
	const { setShowLogin, calculatePriceFromCurrency, currency } =
		useContextGeneral();

	useEffect(() => {
		if (product?.ratingAverage) {
			setRating(product?.ratingAverage);
		}
	}, [product?.ratingAverage]);

	function onChange(newValue: number, storageUniqueId: string) {
		if (user) {
			addUserRateToProduct(storageUniqueId, newValue)
				.then((result) => {
					if (result.data) {
						setRating(result.data);
						setSuccessNotification(t('thankForRateUs'));
					} else {
						setRating(newValue);
					}
				})
				.catch(() => {
					setErrorNotification(t('errorToRateProduct'));
				});
		} else {
			setShowLogin(true);
		}
	}

	function handleSave(value: number) {
		if (product && onAddProduct) {
			onAddProduct(product?.storageUniqueId, value);
		}
	}
	function addOrRemoveFavorite(storageUniqueId: string) {
		if (user) {
			addUserToWhishList(storageUniqueId)
				.then((result) => {
					setWishListUserLogged(result?.data || false);
					setSuccessNotification(
						t(
							result?.data
								? 'successToProductAddedWishList'
								: 'successToProductRemoveWishList'
						)
					);
				})
				.catch(() => {
					setErrorNotification(t('errorToProductAddedWishList'));
				});
		} else {
			setShowLogin(true);
		}
	}

	function calculatePercent(price: number, percent: number) {
		return Number(price) - Number(price * percent) / 100;
	}

	return (
		<Card
			sx={{
				background: 'white',
				borderRadius: 5,
				minHeight: '100%',

				position: 'relative',
				boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.4)',
			}}
		>
			<CardContent
				sx={{
					padding: '0px',
					margin: '0px',
					paddingBottom: '0px !important',
				}}
			>
				<Box
					sx={{
						display: 'flex',
					}}
				>
					<Box
						position='relative'
						width='35%'
						sx={{
							aspectRatio:
								product.productImage?.width / product.productImage?.height,
						}}
					>
						<Link
							href={`/product/${product.productId}/${
								wholesaler ? 'wholesaler' : ''
							}`}
							style={{
								textDecoration: 'none',
								width: '100%',
								color: 'inherit',
							}}
						>
							<ImageWithFallback
								src={product.productImage?.url || ''}
								fallbackImage='/static/favicon/256x256.png'
								alt={product.productName[lng]}
								width={product.productImage?.width}
								height={product.productImage?.height}
								style={{
									width: '100%',
									height: 'auto',
									display: 'flex',
									aspectRatio:
										product.productImage?.width / product.productImage?.height,
								}}
							/>
						</Link>
					</Box>
					<Box
						position='relative'
						sx={{ padding: '1rem 0.5rem', minHeight: 'calc(100% - 2rem)' }}
						width='65%'
					>
						<Box
							position='relative'
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								height: '100%',
							}}
						>
							<Grid container columnSpacing={2}>
								<Grid item xs={10} display='flex' alignItems='center'>
									<Link
										href={`/product/${product.productId}/${
											wholesaler ? 'wholesaler' : ''
										}`}
										style={{
											textDecoration: 'none',
											width: '100%',
											color: 'inherit',
										}}
									>
										<Typography
											variant='ExplicationTextBold'
											lineHeight='15px'
											fontWeight='600'
											color='black'
										>
											{product.productName[lng]}
										</Typography>{' '}
									</Link>
								</Grid>
								<Grid
									item
									xs={2}
									display='flex'
									alignItems='center'
									justifyContent='flex-end'
								>
									<IconButton
										sx={{
											p: 0,
										}}
										aria-label={t('addToWishList')}
										color='primary'
										size='large'
										title={t('addToWishList')}
										onClick={() => {
											addOrRemoveFavorite(product.storageUniqueId);
										}}
									>
										{product?.whishListUserLogged ? (
											<FavoriteIcon
												fontSize='medium'
												sx={{
													color: (theme) => theme.palette.secondary.main,
												}}
											/>
										) : (
											<FavoriteBorderIcon
												fontSize='medium'
												sx={{
													color: (theme) => theme.palette.secondary.main,
												}}
											/>
										)}
									</IconButton>
								</Grid>
								<Grid item xs={12} display='flex' alignItems='center'>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
											width: '100%',
											mt: '4px',
										}}
									>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<Typography
												variant='ExplicationText'
												fontSize='clamp(0.7rem,3.0vw,1rem)'
												color='secondary.main'
												fontWeight='600'
												sx={{
													lineHeight: '1rem',
													...(product.productDiscountPercentage && {
														textDecoration: 'line-through',
														textDecorationColor: '#77160e',
														color: '#77160e',
													}),
												}}
											>
												{`${formatCurrency(
													calculatePriceFromCurrency(product.productSalePrice),
													currency
												)} ${currency}`}
											</Typography>
										</Box>
										<Box>
											<Rating
												precision={0.5}
												value={rating}
												size='small'
												onChange={(e, newValue) => {
													onChange(newValue || 0, product.storageUniqueId);
												}}
												icon={
													<StarSolid
														color={ThemeBasic.palette.secondary.main}
													/>
												}
												emptyIcon={
													<StarOutlined
														color={ThemeBasic.palette.secondary.main}
													/>
												}
											/>
										</Box>
									</Box>
								</Grid>

								{product.productDiscountPercentage > 0 && (
									<Grid item xs={12}>
										<Typography
											variant='ExplicationText'
											fontSize='clamp(0.7rem,3.5vw,1rem)'
											color='secondary.main'
											fontWeight='600'
											sx={{
												lineHeight: 0,
												mt: '10px',
											}}
										>
											{`${formatCurrency(
												calculatePriceFromCurrency(
													calculatePercent(
														product.productSalePrice,
														product.productDiscountPercentage
													)
												),
												currency
											)} ${currency}`}
										</Typography>
									</Grid>
								)}
							</Grid>
							<Grid
								container
								columnSpacing={2}
								sx={{ display: { md: 'block', xs: 'none' } }}
							>
								<Grid item>
									<ButtonAddProductCardShop
										lng={lng}
										onCallback={handleSave}
										limit={product.limit}
									/>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Box>
			</CardContent>
			<CardActions sx={{ display: { xs: 'block', md: 'none' } }}>
				<ButtonAddProductCardShop
					lng={lng}
					onCallback={handleSave}
					limit={product.limit}
				/>
			</CardActions>
		</Card>
	);
};
